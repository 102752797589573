import store from "@/state/store";

export default [
  {
    path: "/login",
    name: "login",
    component: () => import("../views/account/login.vue"),
    meta: {
      title: "Login",
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ name: "default" });
          next();
        } else {
          // Continue to the login page
          next();
        }
      },
    },
  },
  // {
  //   path: "/register",
  //   name: "register",
  //   component: () => import("../views/account/register.vue"),
  //   meta: {
  //     title: "Register",
  //     beforeResolve(routeTo, routeFrom, next) {
  //       // If the user is already logged in
  //       if (store.getters["auth/loggedIn"]) {
  //         // Redirect to the home page instead
  //         next({ name: "default" });
  //       } else {
  //         // Continue to the login page
  //         next();
  //       }
  //     },
  //   },
  // },
  // {
  //   path: "/forgot-password",
  //   name: "forgot-password",
  //   component: () => import("../views/account/forgot-password.vue"),
  //   meta: {
  //     title: "Forgot Password",
  //     beforeResolve(routeTo, routeFrom, next) {
  //       // If the user is already logged in
  //       if (store.getters["auth/loggedIn"]) {
  //         // Redirect to the home page instead
  //         next({ name: "default" });
  //       } else {
  //         // Continue to the login page
  //         next();
  //       }
  //     },
  //   },
  // },
  {
    path: "/logout",
    name: "logout",
    meta: {
      title: "Logout", authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        localStorage.clear();
        sessionStorage.clear();
        localStorage.removeItem('user');
        localStorage.removeItem('userdata');
        localStorage.removeItem('jwt');
        localStorage.removeItem('userid');
        localStorage.removeItem('lang');
        localStorage.removeItem('is_tnc');
        next();
      },
    },
    component: () => import("../views/account/logout")
  },
  {
    path: "/tnc",
    name: "tnc",
    meta: { title: "Terms Conditions and Privacy Policy" },
    component: () => import("../views/account/terms-and-conditions"),
  },
  {
    path: "/privacy-policy",
    name: "privacy-policy",
    meta: { title: "Privacy Policy" },
    component: () => import("../views/pages/privacy-policy"),
  },
  {
    path: "/term-conditions",
    name: "term-conditions",
    meta: { title: "Term Conditions" },
    component: () => import("../views/pages/term-conditions"),
  },
  {
    path: "/",
    component: () => import("@/layouts/vertical.vue"),
    children: [
      {
        path: "/",
        name: "dashboard-main",
        meta: {
          title: "Analytics", authRequired: true,
        },
        component: () => import("../views/dashboard/index"),
      },
      {
        path: "/dashboard/statistics",
        name: "dashboard-statistics",
        meta: {
          title: "Analytics", authRequired: true,
        },
        component: () => import("../views/dashboard/statistics"),
      },
      // {
      //   path: "/dashboard/job",
      //   name: "dashboard-job",
      //   meta: {
      //     title: "Job Dashboard", authRequired: true,
      //   },
      //   component: () => import("../views/dashboard/job/index"),
      // },
      {
        path: "/profile",
        name: "user-profile",
        meta: {
          title: "User", authRequired: true,
        },
        component: () => import("../views/reference/user/profile"),
      },
      {
        path: "/issue/list",
        name: "issue-list",
        meta: { title: "Issues List", authRequired: true },
        component: () => import("../views/issues/index"),
      },
      {
        path: "/issue/new-batch",
        name: "new-batch",
        meta: { title: "New Batch", authRequired: true },
        component: () => import("../views/issues/new"),
      },
      {
        path: "/issue/edit/:id(\\d+)+",
        name: "edit-batch",
        meta: { title: "Edit Batch", authRequired: true },
        component: () => import("../views/issues/edit"),
      },
      {
        path: "/issue/show/:id(\\d+)+",
        name: "show-batch",
        meta: { title: "View Batch", authRequired: true },
        component: () => import("../views/issues/show"),
      },
      {
        path: "/issue/verify",
        name: "issue-verify",
        meta: { title: "Verified List", authRequired: true },
        component: () => import("../views/issues/verification"),
      },
      {
        path: "/issue/approve",
        name: "issue-approve",
        meta: { title: "Approval List", authRequired: true },
        component: () => import("../views/issues/approval"),
      },
      {
        path: "/issue/takedown-request",
        name: "issue-takedown-request",
        meta: { title: "Takedown Request List", authRequired: true },
        component: () => import("../views/issues/takedown-request"),
      },
      {
        path: "/issue/monitoring",
        name: "issue-monitoring",
        meta: { title: "Monitoring List", authRequired: true },
        component: () => import("../views/issues/monitoring"),
      },
      {
        path: "/issue/takedown-confirm",
        name: "issue-takedown-confirm",
        meta: { title: "Takedown Confirmation List", authRequired: true },
        component: () => import("../views/issues/takedown-confirm"),
      },
      {
        path: "/issue/appeal",
        name: "issue-appeal",
        meta: { title: "Appeal List", authRequired: true },
        component: () => import("../views/appeal/index"),
      },
      {
        path: "/issue/appeal/view/:id(\\d+)+",
        name: "issue-appeal-view",
        meta: { title: "Appeal Detail", authRequired: true },
        component: () => import("../views/appeal/detail"),
      },
      {
        path: "/issue/outstanding",
        name: "issue-outstanding",
        meta: { title: "Outstanding List", authRequired: true },
        component: () => import("../views/issues/outstanding"),
      },
      {
        path: "/issue/outstanding-billing",
        name: "issue-outstanding-billing",
        meta: { title: "Outstanding Billing List", authRequired: true },
        component: () => import("../views/issues/outstanding-billing"),
      },
      {
        path: "/issue/written-request",
        name: "issue-written-request",
        meta: { title: "Written Warning List", authRequired: true },
        component: () => import("../views/issues/written-request"),
      },
      {
        path: "/issue/written-warning",
        name: "issue-written-warning",
        meta: { title: "Written Warning List", authRequired: true },
        component: () => import("../views/issues/written-warning"),
      },
      {
        path: "/issue/written-recap",
        name: "issue-written-recap",
        meta: { title: "Written Recap List", authRequired: true },
        component: () => import("../views/issues/written-recap"),
      },
      {
        path: "/issue/notification-letter",
        name: "issue-surat-notifikasi",
        meta: { title: "Notification Letter List", authRequired: true },
        component: () => import("../views/issues/notification-letter"),
      },
      {
        path: "/issue/waning-letter",
        name: "issue-surat-peringatan",
        meta: { title: "Warning Letter List", authRequired: true },
        component: () => import("../views/issues/warning-letter"),
      },
      {
        path: "/issue/revision",
        name: "issue-revision",
        meta: { title: "Revision List", authRequired: true },
        component: () => import("../views/issues/revision"),
      },
      {
        path: "/issue/detail/:id(\\d+)+/b/:batch(\\d+)+",
        name: "show-written-warning",
        meta: { title: "View Batch", authRequired: true },
        component: () => import("../views/issues/show-detail"),
      },
      {
        path: "/issue/letter-log",
        name: "issue-letter-log",
        meta: { title: "Letter Log List", authRequired: true },
        component: () => import("../views/issues/letter-log"),
      },
      {
        path: "/billing",
        name: "billing-index",
        meta: { title: "Billing List", authRequired: true },
        component: () => import("../views/billing/index"),
      },
      {
        path: "/billing-code",
        name: "billing-code-index",
        meta: { title: "Billing Code List", authRequired: true },
        component: () => import("../views/billing/code"),
      },
      {
        path: "/report/verificator",
        name: "report-index",
        meta: { title: "Report List", authRequired: true },
        component: () => import("../views/report/index"),
      },
      {
        path: "/report/verificator-lead",
        name: "report-lead",
        meta: { title: "Report List", authRequired: true },
        component: () => import("../views/report/lead"),
      },
      {
        path: "/billing/detail/:id(\\d+)+",
        name: "billing-detail",
        meta: { title: "Billing Detail", authRequired: true },
        component: () => import("../views/billing/detail"),
      },
      {
        path: "/platform/takedown-request",
        name: "platform-takedown-request",
        meta: { title: "Takedown Request List", authRequired: true },
        component: () => import("../views/platform/takedown-request"),
      },
      {
        path: "/platform/takedown-links",
        name: "platform-takedown-links",
        meta: { title: "Takedown Request Item List", authRequired: true },
        component: () => import("../views/platform/takedown-links"),
      },
      {
        path: "/platform/takedown-confirm",
        name: "platform-takedown-confirm",
        meta: { title: "Takedown Confirm List", authRequired: true },
        component: () => import("../views/platform/takedown-confirm"),
      },
      {
        path: "/platform/appeal",
        name: "platform-appeal",
        meta: { title: "Appeal List", authRequired: true },
        component: () => import("../views/platform/appeal"),
      },
      {
        path: "/platform/new-appeal",
        name: "platform-appeal-create",
        meta: { title: "Appeal Create", authRequired: true },
        component: () => import("../views/platform/appeal-create"),
      },
      {
        path: "/platform/appeal/:id(\\d+)+",
        name: "platform-appeal-detail",
        meta: { title: "Appeal Detail", authRequired: true },
        component: () => import("../views/platform/appeal-detail"),
      },
      {
        path: "/platform/written-warning",
        name: "platform-written-warning",
        meta: { title: "Written Warning List", authRequired: true },
        component: () => import("../views/platform/written-warning"),
      },
      {
        path: "/platform/notification-letter",
        name: "platform-surat-notifikasi",
        meta: { title: "Notification Letter List", authRequired: true },
        component: () => import("../views/platform/notification-letter"),
      },
      {
        path: "/platform/warning-letter",
        name: "platform-surat-peringatan",
        meta: { title: "Warning Letter List", authRequired: true },
        component: () => import("../views/platform/warning-letter"),
      },
      {
        path: "/platform/billing",
        name: "platform-billing",
        meta: { title: "Billing List", authRequired: true },
        component: () => import("../views/platform/billing"),
      },
      {
        path: "/platform/revision",
        name: "platform-revision",
        meta: { title: "Revision List", authRequired: true },
        component: () => import("../views/platform/revision"),
      },
      {
        path: "/platform/billing/create/:id(\\d+)+",
        name: "platform-billing-create",
        meta: { title: "Billing Create", authRequired: true },
        component: () => import("../views/platform/billing-create"),
      },
      {
        path: "/platform/billing/detail/:id(\\d+)+",
        name: "platform-billing-detail",
        meta: { title: "Billing Detail", authRequired: true },
        component: () => import("../views/platform/billing-detail"),
      },
    ],
  },
];  